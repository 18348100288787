import React, { useContext, useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Stack, IconButton, Text, Dialog, TextField, Checkbox, Toggle } from '@fluentui/react';
import Select from "react-select";
import styles from "./Layout.module.css";
import Contoso from "../../assets/Contoso.svg";
import { CopyRegular } from "@fluentui/react-icons";
import { HistoryButton, ShareButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { CosmosDBStatus } from "../../api";
import ModelSwitch from '../../components/ModelSwitch/ModelSwitch';

const options = [
    { value: 'tuckbotgpt35', label: 'ChatGPT 3.5' },
    { value: 'tuckbotgpt4o', label: 'ChatGPT 4.0' },
    { value: 'tuckbotgpt4omini', label: 'ChatGPT 4.0 Mini' }
];


// Import environment variables
declare const process: {
    env: {
      REACT_APP_NODE_ENV: string;
    };
  };
  
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';


  const customStyles = {
    control: (provided: any) => ({
        ...provided,
        borderRadius: '4px',
        borderColor: '#ccc',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#888',
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        borderRadius: '4px',
        zIndex: 9999,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#0078d4' : state.isFocused ? '#e5e5e5' : '#fff',
        color: state.isSelected ? '#fff' : '#333',
        '&:hover': {
            backgroundColor: '#e5e5e5',
            color: '#333',
        },
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: '#333',
    }),
};
const Layout = () => {
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const [shareLabel, setShareLabel] = useState<string | undefined>("Share");
    const [hideHistoryLabel, setHideHistoryLabel] = useState<string>("Hide chat history");
    const [showHistoryLabel, setShowHistoryLabel] = useState<string>("Show chat history");
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;
    const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState<boolean>(false);
    const [isTutorMode, setIsTutorMode] = useState<boolean>(false);
    const [model, setModel] = useState<string>(""); // Add this line
    const [selectedModel, setSelectedModel] = useState(options[0].value);
    const [userRole, setUserRole] = useState<string | null>(null);


    const handleModelChange = async (newValue: any) => {
        const newModel = newValue.value;
        setModel(newModel);
    
        // Perform the fetch request when the model is changed
        const response = await fetch('/conversation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ model: newModel, /* other request data */ }),
        });
        const data = await response.json();
        // Handle the response data
    
        // Fetch the session data to log it in the browser
        const sessionResponse = await fetch('api/check_session', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
        if (!sessionResponse.ok) {
            const errorText = await sessionResponse.text();
            console.error('Error response:', errorText);
            return;
        }
        
        const sessionData = await sessionResponse.json();
        console.log(sessionData); // Log the session data to the console
    
        setSelectedModel(newModel);
    };

    const handleSettingsClick = () => {
        console.log('Settings button clicked');
        setIsSettingsDialogOpen(true);
    };

    const handleSettingsDialogDismiss = () => {
        setIsSettingsDialogOpen(false);
    };

    const handleTutorModeChange = async (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
        setIsTutorMode(isChecked || false);
        const response = await fetch('/api/set_prompt_template', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ promptType: isChecked ? 'tutor' : 'default' })
        });
        if (!response.ok) {
            console.error('Failed to set prompt type');
        }
    };

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 480) {
            setShareLabel(undefined)
            setHideHistoryLabel("Hide history")
            setShowHistoryLabel("Show history")
          } else {
            setShareLabel("Share")
            setHideHistoryLabel("Hide chat history")
            setShowHistoryLabel("Show chat history")
          }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);


      useEffect(() => {
        const checkSetUserRole = async () => {
            try {
                const response = await fetch('/set_role', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    if (!isProduction) {
                        console.error('Error response:', errorText);
                    }
                    return;
                }

                const data = await response.json();
                if (!isProduction) {
                    console.log('Set User role data:', data);
                }
                setUserRole(data.user_role || null);
                // You can dispatch an action or set state based on the user role data if needed
            } catch (error) {
                if (!isProduction) {
                    console.error('Error checking user role:', error);
                }
            }
        };

        checkSetUserRole();
    }, []);
      

      return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center">
                        <img
                            src={ui?.logo ? ui.logo : Contoso}
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>{ui?.title}</h1>
                        </Link>
                        <IconButton
                            iconProps={{ iconName: 'Settings' }}
                            title="Settings"
                            ariaLabel="Settings"
                            onClick={handleSettingsClick}
                        />
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            {/* <Text variant="large">Settings</Text> */}
                            <Select
                                value={options.find(option => option.value === selectedModel)}
                                onChange={handleModelChange}
                                options={options}
                                styles={customStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                        </Stack>
                    </Stack>
                    {ui?.show_share_button &&
                        <Stack horizontal tokens={{ childrenGap: 4 }}>
                            {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                                <HistoryButton onClick={handleHistoryClick} text={appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel} />
                            }
                            <ShareButton onClick={handleShareClick} text={shareLabel} />
                        </Stack>
                    }
                </Stack>
            </header>
            <Outlet />
            <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Share the web app",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog>


    <Dialog
    onDismiss={handleSettingsDialogDismiss}
    hidden={!isSettingsDialogOpen}
    dialogContentProps={{
        title: 'Chat Mode',
        showCloseButton: true
    }}
    >
      <Checkbox
        label="Tutor Me"
        checked={isTutorMode}
        onChange={handleTutorModeChange}
    />
    </Dialog>


        </div>
    );
};

export default Layout;
